import * as Sentry from '@sentry/browser';

export type LogLevel = Extract<
  keyof typeof console,
  'log' | 'info' | 'warn' | 'error' | 'debug' | 'trace'
>;
export type ImplementedConsoleMethod = LogLevel;

export const logLevelToSentryLogLevel: Record<LogLevel, Sentry.SeverityLevel> = {
  'info': 'info',
  'debug': 'debug',
  'log': 'log',
  'warn': 'warning',
  'error': 'error',
  'trace': 'debug',
};
