import {CustomTheme} from '../../../../shared/db-models/store';

/**
 *
 * @param theme
 */
export function updateTheme(theme: CustomTheme | undefined): void {
  if (!theme) {
    const defaultTheme: CustomTheme = {
      primaryColor: `var('--default-primary')`,
      secondaryColor: `var('--default-secondary')`,
      lightColor: `var('--default-light')`,
    };
    return updateTheme(defaultTheme);
  }
  if (theme.primaryColor) document.body.style.setProperty('--primary-store', theme.primaryColor);
  if (theme.secondaryColor) {
    document.body.style.setProperty('--secondary-store', theme.secondaryColor);
  }
  if (theme.lightColor) document.body.style.setProperty('--light-store', theme.lightColor);
}
/**
 *
 * @param theme
 */
export function updateDefaultTheme(theme: CustomTheme) {
  if (theme.primaryColor) document.body.style.setProperty('--default-primary', theme.primaryColor);
  if (theme.secondaryColor) {
    document.body.style.setProperty('--default-secondary', theme.secondaryColor);
  }
  if (theme.lightColor) document.body.style.setProperty('--default-light', theme.lightColor);
}
