export enum DiscountTypes {
  GeneralDiscount = 1,
  ViewersPercentage = 2,
  ViewersItemDiscount = 3,
}
export class DbViewersDiscountModel {
  viewers: number;
  value: number;
  productId?: string;
}

export class SessionViewersDiscountModel extends DbViewersDiscountModel {
  coupon: string;
}

export class DbCouponsDocModel {
  viewersItemDiscounts?: SessionViewersDiscountModel[] = [];
  viewersPercentageDiscounts?: SessionViewersDiscountModel[] = [];
  generalCoupon?: {
    coupon?: SessionViewersDiscountModel['coupon'];
    value?: SessionViewersDiscountModel['value'];
  } = {coupon: '', value: 0};
}

export const enum DiscountAppliesTo {
  All = 'all',
  Item = 'item',
}

export const enum DiscountAppliesWhen {
  CurrentViewersIsMoreThen = 'currentViewersIsMoreThen',
}

export enum DiscountCalculationType {
  Percentage = 1,
  Amount = 2,
}

export type DiscountItemData = {
  productId: string;
};

export type DiscountAppliesToData = null | DiscountItemData;

export type DiscountAppliesWhenData = null | DiscountCurrentViewersIsMoreThenData;

export type DiscountCurrentViewersIsMoreThenData = {
  currentViewersIsMoreThen: number;
};

export type DiscountDescription = {
  calculationType: DiscountCalculationType;

  appliesTo: DiscountAppliesTo;
  appliesToData: DiscountAppliesToData;

  appliesWhen: DiscountAppliesWhen;
  appliesWhenData: DiscountAppliesWhenData;

  value: number;
  couponsCode?: string; // only when accessible, server side only
} | null;

export type DiscountDataInDbSessionModel = {
  discountType: DiscountTypes;
  generalDiscount?: number;
  generalDiscountCalculationType?: DiscountCalculationType;
  viewersPercentageDiscounts: DbViewersDiscountModel[];
  viewersPercentageDiscountsCalculationType?: DiscountCalculationType;
  viewersItemDiscounts: DbViewersDiscountModel[];
  viewersItemDiscountsCalculationType?: DiscountCalculationType;
};

/**
 * @summary this function calculates the discounts status for the session
 * @description
 * this function calculates the discounts status for the session
 * and returns an object with all the relevant data that is needed
 * for both the client and the server to calculate the discounts
 * and to display relevant data to the user from the ui
 * @param sessionDoc holds public data about the session @see DbSessionModel
 * @param currentViewers a function that returns the current viewers of the session, max viewers is accessible from sessionDoc so no need to pass it
 * @param couponsDoc holds the coupons data for the session
 * - (optional), its optional because the function is used in the client side as well, and this data is not available in the client side
 * @see DbCouponsDocModel
 *
 * @returns SessionDiscountsStatus object @see SessionDiscountsStatus
 */
export function calcSessionDiscountsStatus(
  sessionDoc: DiscountDataInDbSessionModel,
  currentViewers: () => number,
  couponsDoc?: DbCouponsDocModel
): SessionDiscountsStatus {
  throw new Error('not implemented');
}

/**
 * @summary this object holds functions to receive the data about the discounts
 * we using functions to receive the data in real time
 * this also allow to avoid calculate again and again what are the discounts that are available for the session
 * on the session doc you always have discount data for all types of discounts on the session, but only one of them active
 */
export type SessionDiscountsStatus = {
  nextGoalCoupon: () => DiscountDescription;
  previousGoalCoupon: () => DiscountDescription;
  allTheCouponsThatAreAvailableForTheSession: () => DiscountDescription[];
  allUnlockedCoupons: () => DiscountDescription[];
  allCouponsToApplyOnCheckout: () => DiscountDescription[];
  allLockedCoupons: () => DiscountDescription[];
};
