import {APP_INITIALIZER, ErrorHandler, NgModule, inject} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxStripeModule} from 'ngx-stripe';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {
  DEBUG_MODE as ANALYTICS_DEBUG_MODE,
  APP_NAME,
  APP_VERSION,
  AngularFireAnalyticsModule,
  COLLECTION_ENABLED,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PreloadAllModules, Router, RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BaseModule} from './base-module/base.module';

import {
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
  USE_DEVICE_LANGUAGE,
} from '@angular/fire/compat/auth';
import {
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR,
} from '@angular/fire/compat/database';
import {
  AngularFirestoreModule,
  SETTINGS as FIRESTORE_SETTINGS,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/functions';
import {
  AngularFireStorageModule,
  USE_EMULATOR as USE_STORAGE_EMULATOR,
} from '@angular/fire/compat/storage';
// import {
//   AngularFireRemoteConfigModule,
//   SETTINGS as REMOTE_CONFIG_SETTINGS,
//   DEFAULTS as REMOTE_CONFIG_DEFAULTS,
// } from '@angular/fire/compat/remote-config';
// import {
//   AngularFirePerformanceModule,
//   PerformanceMonitoringService,
// } from '@angular/fire/compat/performance';
import {CommonModule} from '@angular/common';
import {getAnalytics, provideAnalytics} from '@angular/fire/analytics';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {AngularFireModule, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {connectDatabaseEmulator, getDatabase, provideDatabase} from '@angular/fire/database';
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  FunctionsModule,
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {getRemoteConfig, provideRemoteConfig} from '@angular/fire/remote-config';
import {connectStorageEmulator, getStorage, provideStorage} from '@angular/fire/storage';
import {TraceModule, TraceService} from '@sentry/angular-ivy';
import {environment} from 'src/environments/environment';
import {LogService} from './logger/logger.service';
import {StoresService} from './services/stores.service';
import {SessionService} from './session/shared/session.service';

enum EMULATOR_PORTS {
  RTDB = 8090,
  FIRESTORE = 8080,
  FUNCTIONS = 5001,
  STORAGE = 9199,
}

/**
 *
 * @param http
 */
function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    TraceModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('./app-routing.module').then((m) => {
              return m.AppRoutingModule;
            }),
        },
      ],
      {preloadingStrategy: PreloadAllModules}
    ),
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    NgxPageScrollCoreModule.forRoot({duration: 1000}),
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    // AngularFirePerformanceModule,
    // AngularFireRemoteConfigModule,
    AngularFireAuthGuardModule,
    FunctionsModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      const firestore = initializeFirestore(getApp(), {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
        experimentalForceLongPolling: environment.featureFlags.longPolling,
        ignoreUndefinedProperties: true,
      });
      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, 'localhost', EMULATOR_PORTS.FIRESTORE);
      }
      return firestore;
    }),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideDatabase(() => {
      const database = getDatabase();
      if (environment.useEmulators) {
        connectDatabaseEmulator(database, 'localhost', EMULATOR_PORTS.RTDB);
      }
      return database;
    }),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: true});
      }

      return auth;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', EMULATOR_PORTS.STORAGE);
      }

      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', EMULATOR_PORTS.FUNCTIONS);
      }

      return functions;
    }),
    NgxStripeModule.forRoot(),
  ],
  exports: [BaseModule],
  providers: [
    {
      provide: TraceService,
      deps: [Router],
    },
    {provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig},
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: LogService,
    },
    ScreenTrackingService,
    UserTrackingService,
    // PerformanceMonitoringService,
    {
      provide: FIRESTORE_SETTINGS,
      useValue: {
        ignoreUndefinedProperties: true,
        merge: true,
        experimentalForceLongPolling: environment.featureFlags.longPolling,
        experimentalAutoDetectLongPolling: !environment.featureFlags.longPolling,
      },
    },
    {
      provide: ANALYTICS_DEBUG_MODE,
      useFactory: () => {
        const currentUrl = window.location.href;
        const urlObj = new URL(currentUrl);
        return decodeURIComponent(urlObj.searchParams.get('isDebugMode') ?? '') === 'true';
      },
    },
    {provide: COLLECTION_ENABLED, useValue: true},
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.RTDB] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,

      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.FIRESTORE] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.FUNCTIONS] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.STORAGE] : undefined,
    },
    // {
    //   provide: REMOTE_CONFIG_SETTINGS,
    //   useFactory: () => (isDevMode() ? {minimumFetchIntervalMillis: 10_000} : {}),
    // },
    // {provide: REMOTE_CONFIG_DEFAULTS, useValue: {}},
    {provide: USE_DEVICE_LANGUAGE, useValue: true},
    {provide: APP_VERSION, useValue: '1.0.19'},
    {provide: APP_NAME, useValue: 'Client'},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // intentionally left empty
      },
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  protected sessionService = inject(SessionService);
  protected storesService = inject(StoresService);
  private logService = inject(LogService);
  constructor() {
    this.logService.log('angular started');
    //load session & store & data as soon as possible if located in session
    const regEx = new RegExp(
      /^https:\/\/\w*\.?\w+\.\w+\/(?<storeUrl>\w*)\/?session\/(?<sessionId>[\w\d]+)/gm
    );
    const regExResult = regEx.exec(window.location.href);
    let storeUrl: string;
    let sessionId: string;
    if (regExResult) {
      storeUrl = regExResult[1];
      sessionId = regExResult[2];
      this.storesService.loadActiveStore(storeUrl);
      this.sessionService.getSessionDataDTO(sessionId);
    }
  }
}
