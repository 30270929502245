<button
  [type]="type"
  [class]="btnClasses"
  [class.btn-responsive]="responsive"
  [class.disabled]="disabled"
  [disabled]="disabled"
  [class.btn-rounded]="rounded"
  [class.rtl]="rtl"
  [ngClass]="getSizeAndVariant()"
  (click)="clickHandler($event)"
  [style.--primary]="primaryColor ? primaryColor : null"
  [style.width]="responsiveToText ? 'auto' : fullWidth ? '100%' : ''"
  [ngStyle]="customStyle"
>
  <ng-content></ng-content>
</button>
