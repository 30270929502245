import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import '@angular/localize/init';

import '@total-typescript/ts-reset';

import * as Sentry from '@sentry/angular-ivy';

const isLocal =
  window.location.href.includes('localhost') ||
  window.location.href.includes('127.0.0.1') ||
  environment.useEmulators;

const isProd = environment.production;

Sentry.init({
  dsn: 'https://bf6d25943ab54fba9b4015295f37eae2@o970573.ingest.sentry.io/4505161664167936',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: isProd ? 0.1 : 0.5,
  replaysOnErrorSampleRate: 0.6,
  environment: environment.traceOrigin.domain,
  enabled: !isLocal,
  enableTracing: !isLocal,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.debug(`Bootstrap success`))
  .catch((err) => console.error(err));
