import type {FirestoreTimestamp} from '../types/firestore';

export type IECommercePlatformIntegration =
  | BaseIntegrationFields & (ShopifyPlatformIntegration | AnonymousShopifyPlatformIntegration);

type BaseIntegrationFields = {
  terrificStoreIds: string[];
  storeUrl: string;
  addedByUserId?: string;
  updateByUserId?: string;
  apiUrl?: string;
  country?: string;
  countryCode?: string;
  createDate: FirestoreTimestamp;
  currency?: string;
  currencyCode?: string;
  email?: string;
  extraData?: unknown;
  id: string;
  name?: string;
  plan?: null;
  platform?: string;
  platformUserId?: string;
  subscriptionId?: null | string;
  updateDate?: FirestoreTimestamp;
  uuidNamespace?: string;
};

export type ShopifyPlatformIntegration = {
  storeUrl: string;
  type: 'shopify';
  customIntegrationCredentials: {
    apiKey: string;
    apiSecretKey: string;
    apiToken?: string | undefined;
  };
  productTransformationType: ProductTransformationType;
  productTransformationOptions: {
    // metafieldNamespace: 'custom';
    metafieldKey: string;
    // metafieldType: 'list.product_reference';
  };
};

export type AnonymousShopifyPlatformIntegration = {
  type: 'anonymous-shopify';
};

export enum ProductTransformationType {
  default = 'default',
  mergeByName = 'merge-by-name',
  mergeByMetafield = 'merge-by-metafield',
}

export function integrationDocPath(integrationId: string) {
  return `ecommercePlatformIntegrations/${integrationId}`;
}

export function integrationDocsPath() {
  return `ecommercePlatformIntegrations`;
}
