import {Timestamp} from '@angular/fire/firestore';

export default class TimestampHelper {
  public static fixServerTimestamp(time: any): Timestamp {
    return new Timestamp(time._seconds, time._nanoseconds);
  }

  public static getCurrentDateTimestamp(): Timestamp {
    return Timestamp.fromMillis(Date.now());
  }

  public static getTimestampFromDate(date: Date): Timestamp {
    return Timestamp.fromDate(date);
  }

  public static fixAllTimestampsOnValue<T>(value: T): T {
    if (!value || typeof value !== 'object') {
      return value;
    }
    if ('_seconds' in value && '_nanoseconds' in value) {
      const newValue = TimestampHelper.fixServerTimestamp(value);
      newValue.toMillis();
      return newValue as T;
    }
    return value;
  }
}
