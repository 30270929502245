import {Component} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {environment} from 'src/environments/environment';
import {PlatformMediaCustomableName} from '../../../../../shared/db-models/media';

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
})
export class AppLoaderComponent {
  lottieLoaderOptions: AnimationOptions = {
    animationData: {
      'v': '5.7.1',
      'fr': 25,
      'ip': 0,
      'op': 25,
      'w': 700,
      'h': 700,
      'nm': 'Comp 1',
      'ddd': 1,
      'assets': [
        {
          'id': 'comp_0',
          'layers': [
            {
              'ddd': 1,
              'ind': 1,
              'ty': 4,
              'nm': 'Shape Layer 4',
              'sr': 1,
              'ks': {
                'o': {
                  'a': 0,
                  'k': 100,
                  'ix': 11,
                },
                'rx': {
                  'a': 0,
                  'k': 0,
                  'ix': 8,
                },
                'ry': {
                  'a': 0,
                  'k': 0,
                  'ix': 9,
                },
                'rz': {
                  'a': 0,
                  'k': 0,
                  'ix': 10,
                },
                'or': {
                  'a': 1,
                  'k': [
                    {
                      'i': {
                        'x': 0.833,
                        'y': 0.833,
                      },
                      'o': {
                        'x': 0.167,
                        'y': 0.167,
                      },
                      't': 0,
                      's': [0, 0, 0],
                      'to': [0, 0, 0],
                      'ti': [0, 0, 0],
                    },
                    {
                      'i': {
                        'x': 0.833,
                        'y': 0.833,
                      },
                      'o': {
                        'x': 0.167,
                        'y': 0.167,
                      },
                      't': 10,
                      's': [0, 0, 0],
                      'to': [0, 0, 0],
                      'ti': [0, 0, 0],
                    },
                    {
                      'i': {
                        'x': 0.833,
                        'y': 0.833,
                      },
                      'o': {
                        'x': 0.167,
                        'y': 0.167,
                      },
                      't': 14,
                      's': [99, 0, 0],
                      'to': [0, 0, 0],
                      'ti': [0, 0, 0],
                    },
                    {
                      'i': {
                        'x': 0.833,
                        'y': 0.833,
                      },
                      'o': {
                        'x': 0.167,
                        'y': 0.167,
                      },
                      't': 18,
                      's': [0, 0, 0],
                      'to': [0, 0, 0],
                      'ti': [0, 0, 0],
                    },
                    {
                      't': 76,
                      's': [0, 0, 0],
                    },
                  ],
                  'ix': 7,
                },
                'p': {
                  'a': 0,
                  'k': [346, 284, 0],
                  'ix': 2,
                },
                'a': {
                  'a': 0,
                  'k': [36, -146, 0],
                  'ix': 1,
                },
                's': {
                  'a': 0,
                  'k': [100, 100, 100],
                  'ix': 6,
                },
              },
              'ao': 0,
              'shapes': [
                {
                  'ty': 'gr',
                  'it': [
                    {
                      'ind': 0,
                      'ty': 'sh',
                      'ix': 1,
                      'ks': {
                        'a': 0,
                        'k': {
                          'i': [
                            [0, -4],
                            [0, 324],
                          ],
                          'o': [
                            [0, 344],
                            [-35, 0],
                          ],
                          'v': [
                            [-180, -146],
                            [252, -146],
                          ],
                          'c': true,
                        },
                        'ix': 2,
                      },
                      'nm': 'Path 1',
                      'mn': 'ADBE Vector Shape - Group',
                      'hd': false,
                    },
                    {
                      'ty': 'tm',
                      's': {
                        'a': 1,
                        'k': [
                          {
                            'i': {
                              'x': [0.833],
                              'y': [0.833],
                            },
                            'o': {
                              'x': [0.167],
                              'y': [0.167],
                            },
                            't': 0,
                            's': [99.9],
                          },
                          {
                            'i': {
                              'x': [0.833],
                              'y': [0.833],
                            },
                            'o': {
                              'x': [0.167],
                              'y': [0.167],
                            },
                            't': 52,
                            's': [59.9],
                          },
                          {
                            'i': {
                              'x': [0.833],
                              'y': [0.833],
                            },
                            'o': {
                              'x': [0.167],
                              'y': [0.167],
                            },
                            't': 64,
                            's': [44.719],
                          },
                          {
                            't': 76,
                            's': [0],
                          },
                        ],
                        'ix': 1,
                      },
                      'e': {
                        'a': 1,
                        'k': [
                          {
                            'i': {
                              'x': [0.833],
                              'y': [0.833],
                            },
                            'o': {
                              'x': [0.167],
                              'y': [0.167],
                            },
                            't': 0,
                            's': [100],
                          },
                          {
                            'i': {
                              'x': [0.25],
                              'y': [0.412],
                            },
                            'o': {
                              'x': [0.167],
                              'y': [0.167],
                            },
                            't': 52,
                            's': [60],
                          },
                          {
                            'i': {
                              'x': [0.683],
                              'y': [0.635],
                            },
                            'o': {
                              'x': [0],
                              'y': [0],
                            },
                            't': 64,
                            's': [14.819],
                          },
                          {
                            'i': {
                              'x': [0.797],
                              'y': [0.448],
                            },
                            'o': {
                              'x': [0.431],
                              'y': [1.526],
                            },
                            't': 74,
                            's': [1],
                          },
                          {
                            't': 76,
                            's': [0.1],
                          },
                        ],
                        'ix': 2,
                      },
                      'o': {
                        'a': 0,
                        'k': 0,
                        'ix': 3,
                      },
                      'm': 1,
                      'ix': 2,
                      'nm': 'Trim Paths 1',
                      'mn': 'ADBE Vector Filter - Trim',
                      'hd': false,
                    },
                    {
                      'ty': 'st',
                      'c': {
                        'a': 0,
                        'k': [1, 0.325490196078, 0.63137254902, 1],
                        'ix': 3,
                      },
                      'o': {
                        'a': 0,
                        'k': 100,
                        'ix': 4,
                      },
                      'w': {
                        'a': 0,
                        'k': 99,
                        'ix': 5,
                      },
                      'lc': 2,
                      'lj': 2,
                      'bm': 0,
                      'd': [
                        {
                          'n': 'd',
                          'nm': 'dash',
                          'v': {
                            'a': 0,
                            'k': 10,
                            'ix': 1,
                          },
                        },
                        {
                          'n': 'o',
                          'nm': 'offset',
                          'v': {
                            'a': 0,
                            'k': 0,
                            'ix': 7,
                          },
                        },
                      ],
                      'nm': 'Stroke 1',
                      'mn': 'ADBE Vector Graphic - Stroke',
                      'hd': false,
                    },
                    {
                      'ty': 'tr',
                      'p': {
                        'a': 0,
                        'k': [0, 0],
                        'ix': 2,
                      },
                      'a': {
                        'a': 0,
                        'k': [0, 0],
                        'ix': 1,
                      },
                      's': {
                        'a': 0,
                        'k': [100, 100],
                        'ix': 3,
                      },
                      'r': {
                        'a': 0,
                        'k': 0,
                        'ix': 6,
                      },
                      'o': {
                        'a': 0,
                        'k': 100,
                        'ix': 7,
                      },
                      'sk': {
                        'a': 0,
                        'k': 0,
                        'ix': 4,
                      },
                      'sa': {
                        'a': 0,
                        'k': 0,
                        'ix': 5,
                      },
                      'nm': 'Transform',
                    },
                  ],
                  'nm': 'Shape 1',
                  'np': 4,
                  'cix': 2,
                  'bm': 0,
                  'ix': 1,
                  'mn': 'ADBE Vector Group',
                  'hd': false,
                },
              ],
              'ip': 0,
              'op': 469,
              'st': 0,
              'bm': 0,
            },
          ],
        },
      ],
      'layers': [
        {
          'ddd': 0,
          'ind': 1,
          'ty': 0,
          'nm': 'dot 1',
          'refId': 'comp_0',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11,
            },
            'r': {
              'a': 0,
              'k': 0,
              'ix': 10,
            },
            'p': {
              'a': 0,
              'k': [350, 350, 0],
              'ix': 2,
            },
            'a': {
              'a': 0,
              'k': [350, 350, 0],
              'ix': 1,
            },
            's': {
              'a': 0,
              'k': [100, 100, 100],
              'ix': 6,
            },
          },
          'ao': 0,
          'w': 700,
          'h': 700,
          'ip': 0,
          'op': 153,
          'st': 0,
          'bm': 0,
        },
        {
          'ddd': 0,
          'ind': 2,
          'ty': 0,
          'nm': 'dot 1',
          'refId': 'comp_0',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11,
            },
            'r': {
              'a': 0,
              'k': 0,
              'ix': 10,
            },
            'p': {
              'a': 0,
              'k': [350, 350, 0],
              'ix': 2,
            },
            'a': {
              'a': 0,
              'k': [350, 350, 0],
              'ix': 1,
            },
            's': {
              'a': 0,
              'k': [100, 100, 100],
              'ix': 6,
            },
          },
          'ao': 0,
          'w': 700,
          'h': 700,
          'ip': -25,
          'op': 153,
          'st': -25,
          'bm': 0,
        },
        {
          'ddd': 0,
          'ind': 3,
          'ty': 0,
          'nm': 'dot 1',
          'refId': 'comp_0',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11,
            },
            'r': {
              'a': 0,
              'k': 0,
              'ix': 10,
            },
            'p': {
              'a': 0,
              'k': [350, 350, 0],
              'ix': 2,
            },
            'a': {
              'a': 0,
              'k': [350, 350, 0],
              'ix': 1,
            },
            's': {
              'a': 0,
              'k': [100, 100, 100],
              'ix': 6,
            },
          },
          'ao': 0,
          'w': 700,
          'h': 700,
          'ip': -39,
          'op': 153,
          'st': -51,
          'bm': 0,
        },
      ],
      'markers': [],
    },
  };
  name = PlatformMediaCustomableName.LOTTIE_LOADER;
  public showDefault = environment.client.clientType === 'terrific';
}
