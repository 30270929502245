import type {IAgoraRTCClient, ConnectionState} from 'agora-rtc-sdk-ng';
type Extract<T, U extends T> = T extends U ? T : never;

type ConnectedState = Extract<ConnectionState, 'CONNECTED' | 'RECONNECTING' | 'CONNECTING'>;

export function inConnectionState(
  connectionState: IAgoraRTCClient['connectionState']
): connectionState is ConnectedState {
  // both DISCONNECTED and DISCONNECTING are considered disconnected
  return !connectionState.startsWith('DIS');
}
