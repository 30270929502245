import {AnalyticsView} from '../types/session';
import {ReferralSource} from './events';

export interface RtdbSessionAnalyticsUserMetrics {
  viewers: number;
  maxViewers: number;
  likes: number;
}

export interface DbSessionAnalyticsMetrics {
  [AnalyticsView.Upcoming]?: DbSessionStateAnalyticsMetrics;
  [AnalyticsView.Live]?: DbSessionStateAnalyticsMetrics;
  [AnalyticsView.PostSession]?: DbSessionStateAnalyticsMetrics;
  [AnalyticsView.Total]?: DbSessionStateAnalyticsMetrics;
}

export interface DbSessionStateAnalyticsMetrics {
  setSessionReminderCount?: number;
  addSessionToCalendarCount?: number;
  cartsCreated?: number;
  itemsAddedToCart?: number;
  purchasesCompleted?: number;
  analyticsView: string;
  checkouts?: number;
  salesValue?: number;
  sessionLikes?: number;
  sessionShares?: number;
  uniqueUsers?: number;
  uniqueActiveUsers?: number;
  pollUniqueVoters?: number;
  cartUniqueUsers?: number;
  checkoutUniqueUsers?: number;
  purchaseUniqueUsers?: number;
  pollsMetrics?: PollMetrics[];
  itemsMetrics?: ItemMetrics[];
  uniqueEngagedUsers?: number;
  onlineUsers?: number;
  maxUsers?: number;
  cartsOpen?: number;
  itemsInCarts?: number;
  itemsValueInCarts?: number;
  topReferers?: RefererMetrics[];
  referralSources?: ReferralSources;
  uniqueActiveUsersFromSharedLinks?: number; // number of unique active users entered the session via shared links
  itemsCheckedOutValue?: number;
  itemsCheckedOutCount?: number;
  aov?: number;
  uniqueBroadcastingUsers?: number;
  uniqueWatchTimeUsers?: number;
  sessionLengthMinutes?: number;
  TotalWatchTimeInMinutes?: number;
  checkoutCompleteValue?: number;
  checkoutCompleteUUserCount?: number;
  sessionReport_checkedOutCount: number;
  sessionReport_itemInCartsCount: number;
  sessionReport_uniqueViewersCount: number;
  session_direct_user_count?: number;
  session_direct_revenue?: number;
  session_direct_order_count?: number;
}
export interface DbSessionWatchTime {
  sessionId: string;
  TotalWatchTimeInMinutes: number;
}
export interface ItemMetrics {
  productId: string;
  uniqueViewers: number;
  inCarts: number;
  checkouts: number;
  // For frontend
  productImage?: string;
  productName?: string;
  productPrice?: number;
}

export interface PollMetrics {
  pollId: string;
  uniqueViewers: number;
}

export interface RefererMetrics {
  refererUserId: string;
  refererUserEmail?: string;
  shares: number;
  clicks: number;
  checkouts: number;
}

export interface ReferralSources {
  [ReferralSource.Facebook]: number;
  [ReferralSource.Instagram]: number;
  [ReferralSource.TikTok]: number;
  [ReferralSource.Twitter]: number;
  [ReferralSource.Other]: number;
}

export function metricsCollectionPath(sessionId: string) {
  return `sessions/${sessionId}/analytics/metrics`;
}
