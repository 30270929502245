import {Component, inject} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reminder-confirmed-modal',
  templateUrl: './reminder-confirmed-modal.component.html',
})
export class ReminderConfirmedModalComponent {
  public modal = inject(NgbActiveModal);
}
