import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IcoMoonName} from './icomoonName';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() name: IcoMoonName;
  @Input() size = '16px';
  @Input() color: string;
  @Input() customStyle: Record<string, string>;
}
