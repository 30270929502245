import {DbOrderModel} from '../db-models/order';
import {PaymentSuppliers} from '../db-models/payments';
import {SessionSeatDTO} from './session-data';
const Validator = require('fastest-validator');

export class PaymentDataStripe {
  customerId: string;
  secret: string;
  intentId: string;
}

export class PaymentDataIframe {
  url: string;
}

export enum PaymentResultType {
  Success = 1,
  RequiresAction = 2,
  Error = 3,
  Redirect = 4,
  PostMessageToHostFrame = 5,
}

export const isPaymentSupplierWithRedirect = (supplier: PaymentSuppliers) =>
  [
    PaymentSuppliers.Claro,
    PaymentSuppliers.T1,
    PaymentSuppliers.Sears,
    PaymentSuppliers.ShopifyPermalinks,
    PaymentSuppliers.SDK,
    PaymentSuppliers.SDKWithRedirect,
    PaymentSuppliers.WooCommerce,
  ].includes(supplier);

export const isPaymentSupplierWithCoupon = (supplier: PaymentSuppliers) =>
  [
    PaymentSuppliers.Claro,
    PaymentSuppliers.Sears,
    PaymentSuppliers.ShopifyPermalinks,
    PaymentSuppliers.SDKWithRedirect,
    PaymentSuppliers.SDK,
    PaymentSuppliers.WooCommerce,
  ].includes(supplier);

export type Redirect = {url: string; coupons: string[]};

type ResultData = Redirect | PaymentDataStripe | PaymentDataIframe | DbOrderModel;

export class PaymentResultModel {
  result: PaymentResultType | PaymentResultType[];
  paymentId?: string;
  data?: ResultData;
  order?: DbOrderModel;
  seat?: SessionSeatDTO;
  error?: string;
}

export function resultTypeIs<T extends PaymentResultType>(
  result: PaymentResultModel | PaymentResultModel['result'],
  type: T
): result is {result: T} {
  const resultVal = typeof result === 'object' && 'result' in result ? result.result : result;
  return resultVal === type || (Array.isArray(resultVal) && resultVal.includes(type));
}

export class paymentDetailsForCart {
  cartId: string;
  shippingMethodId?: string | undefined;
  shippingAddressId: string;
  billingAddressId: string;
}

export class paymentDetailsForSessionEntranceFee {}

export interface PaymentDataTransferModel {
  paymentMethodId: string | null;
  isNewPaymentMethod: boolean;
  saveForFutureUse: boolean;
  amountToPay: number;
  sessionId: string;
  details: paymentDetailsForCart | paymentDetailsForSessionEntranceFee;
  paymentReason: 'payForCart' | 'PayForSessionEntranceFee';
  billingAddressId?: string;
  refererUserId: string | null;
}

export class PaymentData implements PaymentDataTransferModel {
  paymentMethodId: string | null;
  isNewPaymentMethod: boolean;
  saveForFutureUse: boolean;
  amountToPay: number;
  sessionId: string;
  details: paymentDetailsForCart | paymentDetailsForSessionEntranceFee;
  paymentReason: 'payForCart' | 'PayForSessionEntranceFee';
  refererUserId: string | null;

  validate() {
    const validator = new Validator();
    const schema = {
      paymentMethodId: {type: 'string', optional: true},
      isNewPaymentMethod: {type: 'boolean'},
      saveForFutureUse: {type: 'boolean'},
      amountToPay: {type: 'number', 'positive': true},
      details: [
        {
          type: 'class',
          instanceOf: paymentDetailsForCart,
        },
        {
          type: 'class',
          instanceOf: paymentDetailsForSessionEntranceFee,
        },
      ],
      paymentReason: {type: 'string'},
      refererUserId: {type: 'string', optional: true},
      sessionId: {type: 'string'},
      $$strict: true,
    };
    const check = validator.compile(schema);
    return check(this);
  }

  constructor(paymentData: PaymentDataTransferModel) {
    this.amountToPay = paymentData.amountToPay;
    this.paymentMethodId = paymentData.paymentMethodId;
    this.isNewPaymentMethod = paymentData.isNewPaymentMethod;
    this.saveForFutureUse = paymentData.saveForFutureUse;
    this.sessionId = paymentData.sessionId;
    this.refererUserId = paymentData.refererUserId;
    this.paymentReason = paymentData.paymentReason;
    if (paymentData.paymentReason === 'PayForSessionEntranceFee') {
      this.details = new paymentDetailsForSessionEntranceFee();
    } else {
      this.details = new paymentDetailsForCart();
    }
    Object.assign(this.details, paymentData.details);

    const validationResult = this.validate();

    if (validationResult !== true) {
      throw new Error(`PaymentData validation failed: ${JSON.stringify(validationResult)}`);
    }
  }
}

export interface CoriunderResponse {
  replyCode: string;
  replyDesc: string;
  trans_date: string;
  trans_amount: string;
  trans_currency: string;
  trans_installments: string;
  trans_refNum: string;
  storage_id: string;
  /*	Description of the payment method used in the transaction.
For credit cards, the description consists of the card type and the last four digits of the card number.
For bank transfers, the description consists of the platform name and the last four digits of the account.
*/
  paymentDisplay: string;
  signature: string;
  client_fullName: string;
  client_phoneNum: string;
  client_email: string;
  recurringSeries_id?: string;
}
