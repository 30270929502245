import {CompareHelpers} from './compare-helpers';
import {MathHelpers} from './math-helpers';
import {DbCartProductModel} from '../db-models/cart';
import {DbViewersDiscountModel, DiscountTypes} from '../db-models/session';
import {DbStoreShippingMethodModel} from '../db-models/store';
import {SessionDataDTO} from '../dto-models/session-data';
import {assertUnreachable, PartiallyRequired} from './type-helpers';
import {DiscountCalculationType, DiscountDataInDbSessionModel} from '../types/discounts';
import {formatCurrency} from './currency-helpers';

interface FullCartPaymentData {
  sessionData?: Readonly<PartiallyRequired<SessionDataDTO, 'id'>> | null;
  cartProducts?: DbCartProductModel[];
  shippingMethods?: DbStoreShippingMethodModel[];
  numberOfProducts?: number;
  priceBeforeDiscount?: number;
  priceAfterDiscount?: number;
  stringDiscount?: string;
  shippingFee?: number;
}

// called from [Website]
export function addTotalsAndPricesAfterDiscounts(data: FullCartPaymentData | null) {
  if (!data?.sessionData) return null;

  const {sessionData, cartProducts} = data;

  data.cartProducts = cartProducts
    ? calculateViewersItemDiscount(cartProducts, sessionData)
    : undefined;
  const cartTotal = calculateCartTotal(data.cartProducts ?? []);

  const {discount, strDiscount} = calculateOverallDiscount(
    cartTotal,
    sessionData.discountType,
    sessionData.viewersPercentageDiscounts,
    sessionData.viewersPercentageDiscountsCalculationType,
    getTotalViewersCount(sessionData),
    sessionData.generalDiscount ?? null,
    sessionData.generalDiscountCalculationType,
    data.sessionData.currency
  );

  data.priceBeforeDiscount = cartTotal;
  data.priceAfterDiscount = cartTotal - discount;
  data.stringDiscount = strDiscount;

  return data;
}

function getTotalViewersCount(sessionData: SessionDataDTO): number {
  const realViewers = sessionData.liveData?.maxViewers ?? 0;
  const fakeViewers = sessionData.additionalViewersManipulation ?? 0;
  return realViewers + fakeViewers;
}

function calculateViewersItemDiscount(
  cartProducts: DbCartProductModel[],
  sessionData: SessionDataDTO
) {
  return cartProducts.map((product) => {
    if (sessionData.liveData) {
      const price = getSessionProductDiscountedPrice(
        sessionData,
        getTotalViewersCount(sessionData),
        product.productId,
        product.originalPrice
      );
      product.price = price;

      if (!product.compareAtPrice && price !== product.originalPrice) {
        product.compareAtPrice = product.originalPrice;
      }
    }
    return product;
  });
}

function calculateCartTotal(cartProducts: DbCartProductModel[]) {
  return cartProducts.reduce(
    (total, product) => total + (product.price ?? 0) * product.quantity,
    0
  );
}

// called from [Website, Functions]
export function calculateOverallDiscount(
  cartTotal: number,
  discountType: DiscountTypes,
  viewersPercentageDiscounts: DbViewersDiscountModel[],
  viewersPercentageDiscountsCalculationType?: DiscountCalculationType,
  viewers?: number | null,
  generalDiscount?: number | null,
  generalDiscountCalculationType?: DiscountCalculationType,
  currency?: string
) {
  let discount = 0;
  let strDiscount = '';

  switch (discountType) {
    case DiscountTypes.GeneralDiscount:
      if (generalDiscount) {
        strDiscount = formatDiscount(generalDiscount, generalDiscountCalculationType, currency);
        discount = calculateDiscountValue(
          cartTotal,
          generalDiscount,
          generalDiscountCalculationType
        );
      }
      break;

    case DiscountTypes.ViewersPercentage:
      const validDiscount = getHighestValidDiscount(viewersPercentageDiscounts, viewers ?? 0);
      if (validDiscount) {
        strDiscount = formatDiscount(
          validDiscount.value,
          viewersPercentageDiscountsCalculationType,
          currency
        );
        discount = calculateDiscountValue(
          cartTotal,
          validDiscount.value,
          viewersPercentageDiscountsCalculationType
        );
      }
      break;

    case DiscountTypes.ViewersItemDiscount:
      //already called in calculateViewersItemDiscount
      break;

    default:
      assertUnreachable(discountType);
  }

  return {
    discount: MathHelpers.roundToTwoDecimalPoints(discount),
    strDiscount,
  };
}

// called from [Website, Functions]
export function formatDiscount(
  discount: number,
  discountCalculationType?: DiscountCalculationType,
  currency?: string
) {
  if (!discount) {
    return '';
  }
  discountCalculationType = discountCalculationTypeOrDefault(discountCalculationType);
  switch (discountCalculationType) {
    case DiscountCalculationType.Percentage:
      return discount + '%';
    case DiscountCalculationType.Amount:
      return formatCurrency(discount, currency);
    default:
      assertUnreachable(discountCalculationType);
  }
}

// called from [Website, Functions]
export function calculateDiscountValue(
  originalPrice: number,
  discount: number,
  discountCalculationType?: DiscountCalculationType
) {
  discountCalculationType = discountCalculationTypeOrDefault(discountCalculationType);
  switch (discountCalculationType) {
    case DiscountCalculationType.Amount:
      // adjust to $1 according to
      // https://terrific-force.monday.com/boards/5325693173/pulses/5591893038/posts/2572735448
      return originalPrice > discount ? discount : originalPrice - 1;
    case DiscountCalculationType.Percentage:
      return (originalPrice * discount) / 100;
    default:
      assertUnreachable(discountCalculationType);
  }
}

function getHighestValidDiscount(discounts: DbViewersDiscountModel[], viewers: number) {
  const validDiscounts = discounts.filter((x) => x.viewers <= viewers);
  return validDiscounts.sort((a, b) => CompareHelpers.compareNumber(a.viewers, b.viewers, true))[0];
}

// called from [Website, Functions]
export function getSessionProductDiscountedPrice(
  sessionDiscountData: DiscountDataInDbSessionModel,
  viewers: number,
  productId: string,
  originalPrice: number
) {
  const discount = getSessionProductDiscount(sessionDiscountData, viewers, productId);
  if (discount <= 0) return originalPrice;
  const discountCalculationType = discountCalculationTypeOrDefault(
    sessionDiscountData.viewersItemDiscountsCalculationType
  );
  return originalPrice - calculateDiscountValue(originalPrice, discount, discountCalculationType);
}

// called from [Website, Functions]
export function getSessionProductDiscount(
  sessionDiscountData: DiscountDataInDbSessionModel,
  viewers: number,
  productId: string
) {
  const discountType: DiscountTypes = sessionDiscountData.discountType;
  const viewersItemDiscounts: DbViewersDiscountModel[] = sessionDiscountData.viewersItemDiscounts;
  if (
    viewers <= 0 ||
    discountType !== DiscountTypes.ViewersItemDiscount ||
    !viewersItemDiscounts?.length
  ) {
    return 0;
  }

  const validDiscount = getHighestValidDiscount(
    viewersItemDiscounts.filter((x) => x.productId === productId),
    viewers
  );
  return validDiscount?.value || 0;
}

export function discountCalculationTypeOrDefault(
  discountCalculationType?: DiscountCalculationType
) {
  return discountCalculationType || DiscountCalculationType.Percentage;
}
