import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {StateHolderService} from './state-holder.service';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  private stateService = inject(StateHolderService);
  //feed Type
  public feedType = environment.client.homePageFeedType ?? 'stores';

  private router = inject(Router);
  public globalStoreUrl$ = this.stateService.globalStoreUrl$;

  public goToSessionWithQueryString(
    storeUrl: string,
    sessionId: string,
    queryParams?: Record<string, string>
  ) {
    let stringQueryObjToUrl = '';
    if (queryParams) {
      stringQueryObjToUrl =
        '?' +
        Object.keys(queryParams)
          .map((key) => key + '=' + queryParams[key])
          .join('&');
    }

    return this.router.navigateByUrl(`/${storeUrl}/session/${sessionId}${stringQueryObjToUrl}`);
  }

  public linkToStore(storeUrl?: string | undefined | null) {
    const hasHomepageStore =
      !!storeUrl && !(storeUrl === this.globalStoreUrl$.value) && this.feedType === 'stores';
    return hasHomepageStore ? ['/', storeUrl] : ['/'];
  }

  public goToStoreUrlWithOptionalParams = (
    storeUrl?: string | null,
    params: unknown[] = [],
    replaceUrl = false
  ) => {
    const base = this.linkToStore(storeUrl);
    return this.router.navigate([...base, ...params], {replaceUrl});
  };

  public linkToStoreSettings(storeUrl?: string) {
    const base = this.linkToStore(storeUrl);
    return [...base, 'manage', 'settings'];
  }

  public goToSessionLink(storeUrl: string, sessionId: string, replaceUrl = false) {
    return this.router.navigateByUrl(this.getSessionLink(storeUrl, sessionId), {replaceUrl});
  }

  public getSessionLink(storeUrl?: string, sessionId?: string) {
    const storeAsString = this.linkToStore(storeUrl).join('');
    const base = storeAsString.endsWith('/') ? storeAsString : storeAsString + '/';
    return base + 'session/' + (sessionId ?? '');
  }

  public reloadPage() {
    return window.location.reload();
  }
  public goToHomePage() {
    return this.router.navigateByUrl('');
  }
}
