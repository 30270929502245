import {Injectable, NgZone, inject} from '@angular/core';
import * as getValue from 'get-value';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  SubscriptionLike,
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
  take,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import {DbCartProductModel} from 'terrific-shared/db-models/cart';
import {
  CartProductChange,
  MessagePurpose,
  MimicFlow,
  ProductChange,
} from 'terrific-shared/ecommerce-platform-integration/sdk/messages';
import {StoresService} from '../stores.service';

import {DbStoreModel} from 'terrific-shared/db-models/store';
import {LazyServiceProvider} from '../lazy-service-provider.service';
import {PostMessengerService} from './post-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class MimicService {
  private lazyLoad = inject(LazyServiceProvider);
  private postService = inject(PostMessengerService);
  private storesService = this.lazyLoad.get(StoresService);
  private zone = inject(NgZone);
  private get store$() {
    return this.storesService().activeStore$.pipe(map((store) => store?.fullData));
  }

  private mimicProcess = new BehaviorSubject<number>(0);
  public isMimicProcessRunning = this.mimicProcess.pipe(
    map((count) => count > 0),
    distinctUntilChanged(),
    shareReplay({refCount: true, bufferSize: 1})
  );

  public watchCart() {
    return this.zone.runOutsideAngular(() => this.watchCartOutsideAngular());
  }

  private watchCartOutsideAngular() {
    const updates = this.postService.listenToMessagesFromThisFrame(
      MessagePurpose.CartChangeNotification
    );
    return updates.pipe(
      withLatestFrom(this.store$),
      mergeMap(([update, store]) => {
        // empty will make next change to not be ignored
        // never will make next change to be ignored and the observable to never complete
        if (!store?.mimicUserActions) return EMPTY;

        return this.sendMimicUserAction(update.data, store);
      }),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  private transformUrlToOpen(
    product: DbCartProductModel | undefined,
    action: MimicFlow,
    store: DbStoreModel
  ) {
    const urlTemplateToOpen =
      store?.mimicUserActions?.[action] ?? product?.externalUrl ?? store?.externalUrl;

    if (!urlTemplateToOpen) return null;

    const url = urlTemplateToOpen.replace(
      /{{(.*?)}}/g,
      (_, path) => getValue({product, store}, path) ?? ''
    );

    console.log('[MimicService] transformUrlToOpen', {
      action,
      urlTemplateToOpen,
      url,
      productUrl: product?.externalUrl,
      storeUrl: store?.externalUrl,
      storeMimicUserActions: store?.mimicUserActions,
    });

    return url;
  }

  private getActionToRunFromProductChange({type}: ProductChange) {
    return {
      [CartProductChange.add]: MimicFlow.addToCart,
      [CartProductChange.updateUp]: MimicFlow.update,
      [CartProductChange.updateDown]: MimicFlow.update,
      [CartProductChange.remove]: MimicFlow.removeFromCart,
    }[type];
  }

  private sendMimicUserAction(pChange: ProductChange, store: DbStoreModel) {
    const action = this.getActionToRunFromProductChange(pChange);
    console.log('[MimicService] sendMimicUserAction', action, pChange);
    const {type, product} = pChange;

    const urlTemplateToOpen = this.transformUrlToOpen(product, action, store);

    // we need to run this outside angular because the iframe will be created outside angular
    // and the events are unrelated to angular Or to the ui

    if (!urlTemplateToOpen) {
      return store?.mimicUserActions?.[action]
        ? throwError(
            () => new Error('[MimicService] no urlTemplateToOpen', {cause: 'no urlTemplateToOpen'})
          )
        : EMPTY;
    }

    return new Observable((observer) => {
      this.mimicProcess.next(this.mimicProcess.value + 1);
      const frame = this.createIframeWithSrc(urlTemplateToOpen);

      try {
        const {received, response} = this.postService.postMessage(
          {
            messageType: MessagePurpose.MimicUserAction,
            data: {
              ...pChange,
              action,
              store,
            },
          },
          frame.contentWindow
        );

        const subscriptions: SubscriptionLike[] = [];

        subscriptions.push(
          received
            .pipe(
              tap((...res) => console.log('[MimicService] postMessage received', ...res)),
              take(1)
            )
            .subscribe({next: observer.next.bind(observer)})
        );

        if (action === MimicFlow.getInCartQuantity) {
          subscriptions.push(
            response.pipe(take(1)).subscribe({
              next: (...res) => {
                console.log('[MimicService] postMessage response ', type, ...res);
                observer.next(...res);
              },
              complete() {
                console.log('[MimicService] postMessage response complete', type);
                observer.complete();
              },
            })
          );
        }
      } catch (error) {
        const errorMessage = `[MimicService] error sending message ${MessagePurpose.MimicUserAction} -`;
        observer.error(new Error(errorMessage, {cause: error}));
        console.error(errorMessage, error);
      }

      return () => {
        this.mimicProcess.next(this.mimicProcess.value - 1);
        console.log('[MimicService] removing iframe', frame);
        console.log('[MimicService] number of currently active processes', this.mimicProcess.value);
        setTimeout(() => frame.remove(), 60000); // remove the frame after 1 minute - just to give devs option to debug
        // frame.remove();
      };
    });
  }
  /**
   * create an iframe off the screen but as visible frame so it will be able to send, receive and execute scripts
   * then return the iframe element
   */
  private createIframeWithSrc(src: string): HTMLIFrameElement {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', src);
    iframe.style.display = 'block';
    iframe.style.position = 'fixed';
    iframe.style.top = '-100000px';
    iframe.style.left = '-100000px';
    iframe.style.border = '0';
    iframe.style.margin = '0';
    iframe.style.padding = '0';
    iframe.style.overflow = 'hidden';
    iframe.style.opacity = '0';

    document.body.appendChild(iframe);
    console.log('[MimicService] created iframe', src, iframe);
    return iframe;
  }
}
