export namespace Collections {
  export const SESSIONS = 'sessions';
  export const SESSION_DATA = 'data';
  export const SESSION_SEATS = 'sessionSeats';

  export function sessionSeats(sessionId: string): string {
    return `${SESSIONS}/${sessionId}/${SESSION_SEATS}`;
  }

  export function sessionData(sessionId: string): string {
    return `${SESSIONS}/${sessionId}/${SESSION_DATA}`;
  }
}

export namespace Docs {
  export const SESSION_STATISTIC_DOC_ID = 'statistic';

  export function sessionDataStatistic(sessionId: string) {
    return `${Collections.sessionData(sessionId)}/${SESSION_STATISTIC_DOC_ID}`;
  }
}
