import {Observable} from 'rxjs';

type ValueCallback = (value: any) => any;

export function recursiveMapOperator<T>(callback: ValueCallback) {
  return (source: Observable<T>): Observable<T> =>
    new Observable((observer) => {
      const subscription = source.subscribe({
        next(value) {
          const [_, newValue] = recursiveMap(value, callback, new Set());
          observer.next(newValue);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });

      return () => subscription.unsubscribe();

      function recursiveMap(obj: any, callback: ValueCallback, visited: Set<any>): [boolean, any] {
        if (visited.has(obj)) {
          return [false, obj]; // Already visited, so just return the original object.
        }

        visited.add(obj);

        let changesWasMade = false;
        if (!obj || typeof obj !== 'object') {
          const value = callback(obj);
          changesWasMade = value !== obj;

          return [changesWasMade, value];
        }

        Object.entries(obj).forEach(([key, val]) => {
          let valueChanged = false;
          if (typeof val === 'object') {
            let res = callback(val);
            if (res !== val) {
              changesWasMade = true;
              obj[key] = res;
            } else {
              [valueChanged, res] = recursiveMap(val, callback, visited);
            }
            if (!valueChanged) return;
            changesWasMade = true;

            // If this an array we spread it into a new object
            // otherwise firebase does not update values inside an array
            if (Array.isArray(res)) {
              obj[key] = [...res];
            } else {
              obj[key] = res;
            }
          } else {
            const value = callback(val);
            if (value === val) return;

            changesWasMade = true;
            obj[key] = value;
          }
        });

        return [changesWasMade, obj];
      }
    });
}
