<app-media-file
  *ngIf="appIsInitializing && !isHomePage"
  [platformMediaCustomableName]="videoPlaceHolderName"
  class="logo-loader-wrapper"
>
  <video [muted]="'muted'" autoplay loop muted playsinline class="logo-loader">
    <source src="assets/videos/waiting-video-animation.mp4" type="video/mp4" />
    {{ 'ERROR.BROWSER_DOEST_SUPPORT_VIDEO_TAG' | translate }}
  </video>
</app-media-file>
<router-outlet></router-outlet>
