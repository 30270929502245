import {ErrorHandler, Injectable, Injector, NgZone, inject} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import * as Sentry from '@sentry/browser';
import stringify from 'fast-safe-stringify';
import {ToastrService} from 'ngx-toastr';
import {Observable, animationFrameScheduler, catchError, retry, tap, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {ImplementedConsoleMethod, LogLevel, logLevelToSentryLogLevel} from './logger-level.enum';

declare global {
  interface Window {
    debugMode: () => void;
  }
}

type MetaData = {
  serviceName: string;
  functionName: string;
  args: unknown[];
  error?: undefined | unknown;
  level?: LogLevel;

  returnValue?: unknown;
  resource?: string;
  additionalInfo?: Record<string, unknown>;
};

type Merge<A, B> = {
  [K in keyof A | keyof B]: K extends keyof A ? A[K] : K extends keyof B ? B[K] : never;
} & B;

@Injectable({
  providedIn: 'root',
})
export class LogService implements ErrorHandler {
  private injector = inject(Injector);
  private get toaster() {
    return this.injector.get(ToastrService, null);
  }
  private zone = inject(NgZone);
  private debugMode = (() => {
    try {
      const debugMode = document.cookie.includes('debugMode=true');
      debugMode && console.log('debugMode is enabled');
      return debugMode;
    } catch (_e) {
      return false;
    }
  })();

  constructor(private afs?: AngularFireFunctions) {
    window.debugMode = () => {
      this.debugMode = !this.debugMode;
      console.log('debugMode', this.debugMode);
      try {
        document.cookie = `debugMode=${this.debugMode};path=/;max-age=31536000`;
      } catch (_e) {
        // ignore
      }
    };
  }

  private alreadyModifiedError = new WeakSet<Error>();
  public transformErrorObject(unknown: unknown, message?: string): void {
    if (!(unknown instanceof Error)) {
      return;
    }
    if (this.alreadyModifiedError.has(unknown)) {
      return;
    }
    unknown.message = `${message ?? 'logged without message'}: ${unknown.message}`;
    this.alreadyModifiedError.add(unknown);
  }

  public handleError(error: unknown): void {
    if (!error) return;
    try {
      const message = this.getMessageFromFirstArg(
        error,
        'logger service caught an unhandled error'
      );

      !environment.production &&
        this.zone.run(() => this.toaster?.error('unhandled error: ' + message));
      return this.error(message, error);
    } catch (error) {
      return this.error('logger service caught an unhandled error in unhandled error', error);
    }
  }
  private logs?: {level: LogLevel; message: string; metadata?: Partial<MetaData>}[] = [];
  private static LOGS_TO_KEEP = 500;

  /**
   *
   * @param level the log level
   * @param message the log message, please use template literals to avoid string concatenation
   * the message should be a string, if you want to log an object, please use the metadata parameter
   * do use a constant log name, so that we can easily search for it in the logs
   * avoid using runtime names like ${this.id} or ${this.name}
   * @param metadata the log metadata, this should be an object
   * @returns void
   */
  private sentToTerrificServer(level: LogLevel, message: string, metadata?: Partial<MetaData>) {
    animationFrameScheduler.schedule(() => {
      this.logs!.push({level, message, metadata});
      if (this.logs!.length > LogService.LOGS_TO_KEEP) {
        this.logs = this.logs!.slice(-LogService.LOGS_TO_KEEP);
      }

      if (level !== 'error' || environment.useEmulators) return;

      const currentPageUrl = window.location.href;

      message = `${currentPageUrl} ~ ${message}`;

      const log = JSON.parse(
        // remove circular references and other unserializable properties
        safeStringify({
          level,
          message,
          metadata: metadata,
          timestamp: new Date().toISOString(),
          localTime: new Date().toLocaleString(),
          logs: this.logs,
        })
      );

      this.afs
        ?.httpsCallable('logsLogger')(log)
        .subscribe({
          error: (err) => Sentry.captureException(err),
        });
    }, 100);
  }
  private readonly lowPriority = ['info', 'debug', 'log'] satisfies Sentry.SeverityLevel[];
  private readonly mediumPriority = ['warning'] satisfies Sentry.SeverityLevel[];
  private readonly highPriority = ['error', 'fatal'] satisfies Sentry.SeverityLevel[];
  private sendToSentry(level: LogLevel, message: string, metadata?: Partial<MetaData>) {
    const sentryLevel = logLevelToSentryLogLevel[level];
    this.addBreadcrumb(sentryLevel, message, metadata);
    if (this.lowPriority.includes(sentryLevel)) {
      return;
    }
    if (this.mediumPriority.includes(sentryLevel)) {
      return Sentry.captureMessage(message, sentryLevel);
    }
    if (this.highPriority.includes(sentryLevel)) {
      return Sentry.captureException(message);
    }
    return this.addBreadcrumb('error', 'unknown log level', metadata);
  }
  private addBreadcrumb(
    level: Sentry.SeverityLevel,
    message: string,
    metadata?: Partial<MetaData>
  ) {
    try {
      Sentry.addBreadcrumb(
        {
          category: message.split('~').at(0),
          data: metadata,
          message,
          level,
        },
        metadata
      );
    } catch (error) {
      console.error('addBreadcrumb error', error);
    }
  }

  public isDebugMode() {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    return decodeURIComponent(urlObj.searchParams.get('isDebugMode') ?? '') === 'true';
  }

  private static readonly noOperation = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  };

  public get debug() {
    return this.isDebugMode()
      ? (message: string, ...data: unknown[]) => this.consoleProxy.debug(message, ...data)
      : LogService.noOperation;
  }
  public get info() {
    return this.isDebugMode()
      ? (message: string, ...data: unknown[]) => this.consoleProxy.info(message, ...data)
      : LogService.noOperation;
  }
  public get warn() {
    return this.isDebugMode()
      ? (message: string, ...data: unknown[]) => this.consoleProxy.warn(message, ...data)
      : LogService.noOperation;
  }
  public get error() {
    return this.consoleProxy.error as (
      message: string,
      errorObject: unknown,
      ...data: unknown[]
    ) => void;
  }
  public get log() {
    return this.isDebugMode()
      ? (message: string, ...data: unknown[]) => this.consoleProxy.log(message, ...data)
      : LogService.noOperation;
  }

  public logWithMetadata(
    metadata: Partial<MetaData> = {}
  ): Merge<Pick<Console, ImplementedConsoleMethod>, Pick<LogService, 'logWithMetadata'>> {
    const {serviceName, functionName, resource} = metadata;
    const messagePreFix = [serviceName, functionName, resource].filter(Boolean).join(' ~ ');
    return new Proxy(
      {
        ...this,
        ...console,
      },
      {
        get: (_target, prop: ImplementedConsoleMethod | 'logWithMetadata', _receiver) => {
          if (prop === 'logWithMetadata')
            return (additionalMetadata: Partial<MetaData>) => {
              return this.logWithMetadata({
                ...metadata,
                ...additionalMetadata,
              });
            };
          return (message: unknown, ...data: unknown[]) => {
            const messagePreFixWithProp = `${messagePreFix ?? 'logger service'} ~ ${prop}${
              typeof message === 'string' ? ` ~ ${message}` : '~ without message'
            }`;
            typeof message !== 'string' && data.unshift(message);

            return this.isDebugMode() || prop === 'error'
              ? this.consoleProxy[prop](messagePreFixWithProp, ...data, metadata)
              : LogService.noOperation;
          };
        },
      }
    );
  }

  private getMessageFromFirstArg(unknown: unknown, messagePrefixWhenNotString?: string): string {
    if (typeof unknown === 'string') {
      return unknown;
    }
    if (!(unknown instanceof Error)) {
      return messagePrefixWhenNotString ?? 'logged without message';
    }
    if (this.alreadyModifiedError.has(unknown)) {
      return unknown.message;
    }
    return `${messagePrefixWhenNotString ?? 'logged without message'}: ${unknown.message}`;
  }

  private consoleProxy = new Proxy<Pick<Console, ImplementedConsoleMethod>>(console, {
    get: (target, prop: ImplementedConsoleMethod, receiver) => {
      return (message: unknown, ...data: unknown[]) => {
        const isError = message instanceof Error;
        if (isError) {
          this.transformErrorObject(message);
        }
        const messageString =
          typeof message === 'string' ? message : this.getMessageFromFirstArg(message);
        if (typeof message !== 'string') {
          data.unshift(message);
        }
        const combinedMetadata = {
          level: prop,
          args: [message, ...data],
        } satisfies Partial<MetaData>;

        this.sentToTerrificServer(prop, messageString, combinedMetadata);
        this.sendToSentry(prop, messageString, combinedMetadata);

        if (!this.debugMode && prop !== 'error' && environment.production) return;

        Reflect.get(target, prop, receiver)(messageString, ...data, combinedMetadata);
      };
    },
  });
  /**
   * Wraps a given function with additional logging and error handling capabilities.
   *
   * It enhances the function by logging the start of its execution,
   * the results upon successful completion, and any errors should they occur.
   *
   * This wrapper is specifically designed for functions that return Observables,
   * making it suitable for asynchronous operations in reactive programming.
   *
   * @param fn The function to be wrapped. This function should return an Observable.
   * @param uniqueMessageIdentifier Name of the calling entity, used to identify the caller in logs.
   * @param fnName Name of the function being executed, which will be logged to help trace its execution and outcomes.
   * @param args Arguments to be passed to the function `fn`.
   * @param resourceName Optional. Name of the resource being acted upon, providing more context in the logs. If provided, it is included in the log messages for easier identification of the operation's scope.
   *
   * @returns An Observable that emits the result of the wrapped function call. This Observable integrates logging of the call's initiation, its result, or any encountered error, thereby enhancing observability and error handling.
   */
  public loggedFn<T extends Observable<unknown>>(
    fn: T,
    uniqueMessageIdentifier: string,
    fnName: string,
    additionalData?: object
  ) {
    const message = `${uniqueMessageIdentifier} ~ ${fnName}`;
    this.debug(message + ' ~ started', {additionalData});
    return fn.pipe(
      tap((result) =>
        this.debug(message + ' ~ result', {
          result,
        })
      ),
      catchError((e) => {
        return this.logAndRetAThrowError(message, e, additionalData);
      })
    ) as T;
  }

  public logAndRetAThrowError(
    message: string,
    e: unknown,
    additionalData?: object
  ): Observable<never> {
    this.error(message, e, additionalData);
    return throwError(() => {
      return e;
    });
  }

  public tapLog<T>(messageOrMetadata: string | Partial<MetaData>) {
    if (typeof messageOrMetadata !== 'string') {
      return (source: Observable<T>): Observable<T> =>
        source.pipe(
          tap((value) => this.logWithMetadata(messageOrMetadata).debug('emitted', value)),
          catchError((e) => {
            this.logWithMetadata(messageOrMetadata).error(e);
            return throwError(() => e);
          })
        );
    }
    return (source: Observable<T>): Observable<T> =>
      source.pipe(
        tap((value) => this.debug(messageOrMetadata, value)),
        catchError((e) => {
          this.error(messageOrMetadata, e);
          return throwError(() => e);
        })
      );
  }

  public tapLogWithRetry<T>(messageOrMetadata: string | Partial<MetaData>) {
    return (source: Observable<T>): Observable<T> =>
      this.tapLog<T>(messageOrMetadata)(
        source.pipe(retry({count: 3, delay: 300, resetOnSuccess: true}))
      );
  }
}

function safeStringify(obj: unknown): string {
  try {
    return JSON.stringify(obj);
  } catch (_e) {
    return stringify(obj, undefined, undefined, {depthLimit: 3, edgesLimit: 10});
  }
}
