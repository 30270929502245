import {inject, Injectable} from '@angular/core';
import {objectVal} from '@angular/fire/database';
import {asyncScheduler, NEVER, Observable} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  observeOn,
  shareReplay,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import {LogService} from 'src/app/logger/logger.service';
import {
  DbSessionLiveDataModel,
  DbSessionMessageModel,
  DbSessionModel,
  RTDB_PER_SESSION,
  RTDB_PER_STORE,
} from '../../../../../shared/db-models/session';
import {
  convertRTDBRecordResponseToRecordOfSessionDataProductDTO,
  SessionDataDTO,
  SessionDataProductDTO,
} from '../../../../../shared/dto-models/session-data';
import {RtdbService as FirebaseRtdbService} from '../../firebase/rtdb.service';
import {SESSIONS_NODE} from '../../../../../shared/db-models/rtdb-constants';

type SessionData = SessionDataDTO | DbSessionModel | undefined | null;

@Injectable({
  providedIn: 'root',
})
export class SessionRtdbService {
  private rtdbService = inject(FirebaseRtdbService);
  private logService = inject(LogService);

  private getRtdbInfo(session: SessionData) {
    return session?.rtdbInfo?.state === 'ACTIVE' ? session.rtdbInfo : null;
  }

  private getSessionRtdbUrl(session: SessionData) {
    const databaseUrl = this.getRtdbInfo(session)?.databaseUrl;

    if (!databaseUrl) return null;

    if (databaseUrl.includes('127.0.0.1') || databaseUrl.includes('localhost')) {
      return session?.id ?? '';
    }

    return databaseUrl;
  }

  private getSessionRtdbObjectVal<T>(session: SessionData, path: string) {
    const database = this.getSessionRtdbUrl(session);
    if (database === null || database === undefined) {
      return NEVER;
    }

    const query = this.rtdbService.get(database, this.rtdbPath(session, path));
    if (!query) return NEVER;
    return objectVal<T | null>(query).pipe(
      observeOn(asyncScheduler),
      shareReplay({refCount: true, bufferSize: 1, scheduler: asyncScheduler})
    );
  }

  public getSessionRtdbLiveLiveData(session: SessionData) {
    return this.getSessionRtdbObjectVal<DbSessionLiveDataModel>(session, 'sessionLiveData');
  }

  public getLiveRtdbMessages(session$: Observable<SessionData | undefined>) {
    return session$.pipe(
      map((session) => {
        return {
          session,
          dbUrl: this.getSessionRtdbUrl(session),
        };
      }),
      distinctUntilChanged((previous, current) => previous.dbUrl === current.dbUrl),
      switchMap(({session, dbUrl}) => {
        const CHAT_CAPACITY = 120;
        if (!dbUrl) return NEVER;

        return this.rtdbService.getListLastNItems<DbSessionMessageModel>(
          dbUrl,
          this.rtdbPath(session, 'messages'),
          CHAT_CAPACITY
        );
      })
    );
  }

  private rtdbPath(session: SessionData, path: string): string {
    if (session?.rtdbVersion == RTDB_PER_SESSION) {
      return path;
    }
    if (session?.rtdbVersion == RTDB_PER_STORE) {
      return `${SESSIONS_NODE}/${session.id}/${path}`;
    }
    throw new Error(`not a valid RTDB version in session '${session?.id}`);
  }

  public getLiveRTDBProducts(session: SessionData) {
    this.logService.debug('SessionRtdbService ~ getLiveRTDBProducts', session);
    return this.getSessionRtdbObjectVal<Record<string, SessionDataProductDTO>>(
      session,
      'products'
    ).pipe(
      throttleTime(1000, undefined, {leading: true, trailing: true}),
      map((val) => {
        this.logService.debug('SessionRtdbService ~ getLiveRTDBProducts ~ map', val);
        return convertRTDBRecordResponseToRecordOfSessionDataProductDTO(val);
      }),
      map((val) => {
        return {productsAsMap: val ?? {}, products: Object.values(val ?? {})};
      })
    );
  }
}
