import {Component, Input, ViewChild} from '@angular/core';
import {Output, EventEmitter} from '@angular/core';
import {CodeInputComponent} from 'angular-code-input';

@Component({
  selector: 'app-split-input',
  templateUrl: './split-input.component.html',
})
export class SplitInputComponent {
  @Output() codeFull = new EventEmitter<string>();
  @Input() isSendingToServer = false;

  @ViewChild('codeInput') codeInput: CodeInputComponent | undefined;

  public codeCompleted(code: string) {
    this.codeFull.emit(code);
  }

  public clearInput() {
    this.codeInput?.reset();
  }
}
