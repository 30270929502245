<app-base-modal [showCloseButton]="true" (closePopUp)="modal.close()">
  <div class="d-flex flex-column justify-content-center container">
    <div class="d-flex flex-column container-text">
      <h2 class="fw-bold m-0 text-center title">
        {{ title }}
      </h2>
      <p class="mb-0 text-center description">
        {{ description }}
      </p>
    </div>
    <div class="d-flex flex-column-reverse flex-xl-row justify-content-around">
      <app-button
        *ngIf="noButtonText"
        [btnClasses]="'mr-xl-2'"
        [variant]="'light'"
        [size]="'small'"
        [fullWidth]="true"
        (clickEmitter)="no()"
        [customStyle]="{'background': '#f3f3f3', 'color': '#000'}"
      >
        {{ noButtonText }}
      </app-button>
      <app-button
        [btnClasses]="'mb-2 mb-xl-0 ml-xl-2'"
        (clickEmitter)="yes()"
        [size]="'small'"
        [fullWidth]="true"
      >
        {{ yesButtonText }}
      </app-button>
    </div>
  </div>
</app-base-modal>
