import {Injectable, inject} from '@angular/core';
import {getApp} from '@angular/fire/app';
import {
  Database,
  getDatabase,
  limitToLast,
  onValue,
  orderByKey,
  query,
  ref,
} from '@angular/fire/database';
import {EMPTY, Observable, asyncScheduler} from 'rxjs';
import {observeOn, shareReplay, throttleTime} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

type NoneEmptyString = `${string}${string}`;
const DEFAULT_LIST_CAPACITY = 120;

@Injectable({
  providedIn: 'root',
})
export class RtdbService {
  private defaultRtdb = inject(Database);
  get(databaseUrl: string, path: NoneEmptyString) {
    if (0 === path.length) return null;
    if (environment.useEmulators) {
      const database = this.defaultRtdb;
      return ref(database, `${databaseUrl.toLowerCase()}/${path}`);
    }
    const database = databaseUrl.length ? getDatabase(getApp(), databaseUrl) : this.defaultRtdb;
    return ref(database, path);
  }

  getListLastNItems<T extends object>(
    databaseUrl: string,
    path: NoneEmptyString,
    n = DEFAULT_LIST_CAPACITY
  ) {
    const dbRef = this.get(databaseUrl, path);
    if (!dbRef) return EMPTY;

    const getLastMessages = query(dbRef, orderByKey(), limitToLast(n));

    return new Observable<(T & {id: string})[]>((subscriber) => {
      return onValue(
        getLastMessages,
        (snapeshot) => {
          const items: (T & {id: string})[] = [];
          snapeshot.forEach((child) => {
            items.push({
              id: child.key,
              ...child.val(),
            });
          });
          subscriber.next(items);
        },
        // error callback, this is called when the request fails
        subscriber.error.bind(subscriber)
      );
    }).pipe(
      observeOn(asyncScheduler),
      shareReplay({refCount: true, bufferSize: 1, scheduler: asyncScheduler}),
      throttleTime(10, undefined, {leading: false, trailing: true})
    );
  }
}
