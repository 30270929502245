import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {ErrorHandler, NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {LottieModule} from 'ngx-lottie';
import {LogService} from '../logger/logger.service';
import {AppLoaderComponent} from './app-loader/app-loader.component';
import {MediaFileComponent} from './media-file/media-file.component';

@NgModule({
  declarations: [AppLoaderComponent],
  imports: [
    LottieModule.forRoot({player: () => import('lottie-web')}),
    CommonModule,
    NgSelectModule,
    TranslateModule,
    MediaFileComponent,
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: ErrorHandler,
      useValue: LogService,
    },
  ],
  exports: [
    // -- declarations --
    AppLoaderComponent,
    // -- imports --
    LottieModule,
    CommonModule,
    NgSelectModule,
    TranslateModule,
    MediaFileComponent,
  ],
})
export class BaseModule {}
