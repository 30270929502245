export interface TranscodingConfig {
  height: number;
  width: number;
  fps: number;
  bitrate: number;
}

export const TRANSCODING_CONFIG_LOW_STREAM: TranscodingConfig = {
  height: 360,
  width: 640,
  fps: 15,
  bitrate: 400,
};

// Export the configurations
export const TRANSCODING_CONFIG_1080P_30FPS: TranscodingConfig = {
  height: 1080,
  width: 1920,
  fps: 30,
  bitrate: 5000,
};

export const TRANSCODING_CONFIG_1080P_60FPS: TranscodingConfig = {
  height: 1080,
  width: 1920,
  fps: 60,
  bitrate: 6500,
};

export const TRANSCODING_CONFIG_720P_30FPS: TranscodingConfig = {
  height: 720,
  width: 1280,
  fps: 30,
  bitrate: 3420,
};

export const TRANSCODING_CONFIG_720P_60FPS: TranscodingConfig = {
  height: 720,
  width: 1280,
  fps: 60,
  bitrate: 4500,
};

export const RECORDING_CONFIG_1080P_30FPS: TranscodingConfig = {
  height: 1920,
  width: 1080,
  fps: 30,
  bitrate: 5000,
};

export const RECORDING_CONFIG_1080P_60FPS: TranscodingConfig = {
  height: 1920,
  width: 1080,
  fps: 60,
  bitrate: 6500,
};

export const RECORDING_CONFIG_720P_30FPS: TranscodingConfig = {
  height: 1280,
  width: 720,
  fps: 30,
  bitrate: 3420,
};

export const RECORDING_CONFIG_720P_60FPS: TranscodingConfig = {
  height: 1280,
  width: 720,
  fps: 60,
  bitrate: 4500,
};

export const transcodingPreset: Record<string, TranscodingConfig> = {
  '1080p30fps': TRANSCODING_CONFIG_1080P_30FPS,
  '1080p60fps': TRANSCODING_CONFIG_1080P_60FPS,
  '720p30fps': TRANSCODING_CONFIG_720P_30FPS,
  '720p60fps': TRANSCODING_CONFIG_720P_60FPS,
  'lowStream:': TRANSCODING_CONFIG_LOW_STREAM,
};

export const recordingPreset: Record<string, TranscodingConfig> = {
  '1080p30fps': RECORDING_CONFIG_1080P_30FPS,
  '1080p60fps': RECORDING_CONFIG_1080P_60FPS,
  '720p30fps': RECORDING_CONFIG_720P_30FPS,
  '720p60fps': RECORDING_CONFIG_720P_60FPS,
};
