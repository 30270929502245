import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import type {ProductCustomizationImage} from '../../../../shared/db-models/product';
import {Observable, Subject} from 'rxjs';

export class FileHelpers {
  public static validateFile(
    file: File,
    _allowedExtensions: string,
    maxFileSizeInMb: number
  ): string {
    let message: string;

    const allowedExtensions = _allowedExtensions.toLowerCase();

    const extension = FileHelpers.getFileExtension(file).toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      message = `Invalid file type. The allowed type are: ${allowedExtensions}`;
    } else if (file.size > 1024 * 1024 * maxFileSizeInMb) {
      message = `File is too big. Max allowed file size is: ${maxFileSizeInMb}MB`;
    } else {
      message = 'OK';
    }

    return message;
  }

  public static getFileExtension(file: File): string {
    const parts = file.name.split('.');
    return parts[parts.length - 1];
  }

  public static getImagePreview(
    file: File,
    sanitizer: DomSanitizer,
    // eslint-disable-next-line no-unused-vars
    callback: (preview: string | SafeHtml) => void
  ) {
    const reader = new FileReader();

    const extension = FileHelpers.getFileExtension(file);
    if (extension.toLowerCase() === 'svg') {
      reader.onload = (e) => {
        const contents = e.target?.result;
        if (file.type.indexOf('svg') > 0 && contents) {
          callback(sanitizer.bypassSecurityTrustHtml(contents as string));
        }
      };

      reader.readAsText(file);
    } else {
      reader.onload = (e) => {
        if (e.target?.result) callback(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }

  public static validateProductCustomFile(
    file: File,
    _allowedExtensions: string,
    maxFileSizeInMb: number,
    productCustomizationImage: ProductCustomizationImage
  ): Observable<string> {
    const subject = new Subject<string>();
    const message = subject.asObservable();
    const allowedExtensions = _allowedExtensions.toLowerCase();

    const extension = FileHelpers.getFileExtension(file).toLowerCase();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        if (
          width < productCustomizationImage.imageMinimumWidth ||
          width > productCustomizationImage.imageMaxWidth ||
          height < productCustomizationImage.imageMinimumHeight ||
          height > productCustomizationImage.imageMaximumHeight
        ) {
          subject.next(`Image exceeds the dimension restrictions:
             ${productCustomizationImage.imageMinimumWidth} x ${productCustomizationImage.imageMinimumHeight}
             ${productCustomizationImage.imageMaxWidth} x ${productCustomizationImage.imageMaximumHeight}`);
        } else if (!allowedExtensions.includes(extension)) {
          subject.next(`Invalid file type. The allowed type are: ${allowedExtensions}`);
        } else if (file.size > 1024 * 1024 * maxFileSizeInMb) {
          subject.next(`File is too big. Max allowed file size is: ${maxFileSizeInMb}MB`);
        } else {
          subject.next(`OK`);
        }

        subject.complete();
      };
    };

    return message;
  }

  public static getImageExtensionFromUrl(url: string): string | null {
    let isFound = url.includes('.png');
    if (isFound) return 'png';

    isFound = url.includes('.jpg');
    if (isFound) return 'jpg';

    isFound = url.includes('.jpeg');
    if (isFound) return 'jpeg';

    return null;
  }
}
