import {inject, Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import type {
  IAgoraRTC,
  IAgoraRTCClient,
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteVideoTrack,
  NetworkQuality,
  VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';
import {BehaviorSubject, lastValueFrom, Subscription, timer} from 'rxjs';
import {filter, map, retry} from 'rxjs/operators';
import {LogService} from 'src/app/logger/logger.service';
import {environment} from '../../../environments/environment';
import {LanguageService} from '../../language.service';
import {SessionService} from '../../session/shared/session.service';
import {AnalyticsService} from '../analytics.service';
import {AppService} from '../app.service';
import {StateHolderService} from '../state-holder.service';
import {inConnectionState} from './utils';
import {requestMediaPermissions} from './permission-checker';
import {StoresService} from '../stores.service';
import {TRANSCODING_CONFIG_LOW_STREAM, transcodingPreset} from '../../../../../shared/types/agora';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../users.service';

let _agora: IAgoraRTC | undefined;
let _client: IAgoraRTCClient | undefined;
let _waiter: Promise<IAgoraRTC> | undefined;
let _networkQuality: NetworkQuality | undefined;
const AgoraRTC = async (): Promise<IAgoraRTC> => {
  if (!_agora) {
    const {default: Agora} = await import('agora-rtc-sdk-ng');
    _waiter =
      _waiter ??
      new Promise((resolve) => {
        _agora = Agora;
        _client = _agora.createClient({mode: 'live', codec: 'vp8', role: 'host'});
        _client.enableDualStream().then(() => console.info('Dual stream enabled))'));
        _client.setLowStreamParameter(TRANSCODING_CONFIG_LOW_STREAM);
        _client.on('network-quality', (quality) => {
          _networkQuality = quality;
        });
        _agora.setLogLevel(2);
        console.trace('AgoraRTC initialized');
        resolve(_agora);
      });
    await _waiter;
  }
  return _agora!;
};

@Injectable({
  providedIn: 'root',
})
export class AgoraService {
  private fns = inject(AngularFireFunctions);
  protected router = inject(Router);
  private analytics = inject(AnalyticsService);
  private state = inject(StateHolderService);
  private appService = inject(AppService);
  private languageService = inject(LanguageService);
  private sessionService = inject(SessionService);
  private logService = inject(LogService);
  private storesService = inject(StoresService);
  private toastr = inject(ToastrService);
  private usersService = inject(UsersService);

  public isPromoVideoPlaying$ = new BehaviorSubject<boolean>(false); // State to track whether a video is playing

  public get client() {
    return _client;
  }

  public chosenCamera: MediaDeviceInfo | undefined;
  public chosenMicrophone?: MediaDeviceInfo | undefined;

  public recordingSid?: string;
  private broadcastToken?:
    | {
        broadcastToken: string;
        broadcastTokenUid: number;
        sessionId: string;
      }
    | undefined;

  private permissionsPromise: Promise<boolean> | null = null;
  private lastPermissionCheck = 0;
  private readonly PERMISSIONS_CACHE_TIME = 1000; // 1 second cache

  public getClient() {
    const client = this.client;
    if (!client) return undefined;
    // if the host is streaming is means that he thinks he is connected
    // so in that case it doesn't matter if the connection state is not connected
    if (this.state.isAgoraPlayingVideo$.value) return client;
    if (!inConnectionState(client.connectionState)) return undefined;
    return this.client;
  }
  private async refreshPermissions(stopCurrentTracks = true): Promise<boolean> {
    const now = Date.now();

    // Return cached result if within cache time
    if (this.permissionsPromise && now - this.lastPermissionCheck < this.PERMISSIONS_CACHE_TIME) {
      return this.permissionsPromise;
    }

    // Create new promise and cache it
    this.lastPermissionCheck = now;
    this.permissionsPromise = (async () => {
      try {
        return (await requestMediaPermissions(undefined, stopCurrentTracks)) === true;
      } catch (error) {
        return false;
      }
    })();

    return this.permissionsPromise;
  }

  public async pushPromoToAgoraChannel(videoUrl: string, sessionId: string, storeId: string) {
    if (this.isPromoVideoPlaying$.value) {
      this.toastr.info(
        this.languageService.translateSync('LIVESESSION.PROMOTION_VIDEO_ALREADY_PLAYING')
      );
      return;
    }

    if (this.state.iAmBroadcasting$.value !== true) {
      this.toastr.info(
        this.languageService.translateSync(
          'LIVESESSION.PROMOTION_VIDEO_NOT_ALLOWED_BEFORE_BROADCAST'
        )
      );
      return;
    }

    this.isPromoVideoPlaying$.next(true);
    let canvasTrack: any;
    let audioTrack: any;
    const wasBroadcasting = this.state.isAgoraPlayingVideo$.value;

    try {
      if (!this.client) throw new Error('Agora client is not initialized or not joined.');

      const videoElement = await this.createVideoElement(videoUrl);

      if (wasBroadcasting) await this.stopVideoBroadcast();

      const {canvas, ctx} = this.initializeCanvas();
      this.attachCanvasToLocalView(canvas);

      this.startDrawingFrames(videoElement, canvas, ctx);

      const agora = await AgoraRTC();
      canvasTrack = this.createAgoraCanvasTrack(canvas, agora);

      try {
        audioTrack = this.createAgoraAudioTrack(videoElement, agora);
        await this.client.publish([canvasTrack, audioTrack]);
      } catch (audioError) {
        console.warn('No audio track found or failed to create audio track:', audioError);
        await this.client.publish([canvasTrack]); // Publish only the video track
      }

      videoElement.onended = async () =>
        await this.handleVideoEnd(canvasTrack, audioTrack, wasBroadcasting, sessionId, storeId);

      await videoElement.play();
    } catch (error) {
      console.error('Error pushing video to Agora stream via canvas:', error);
      if (wasBroadcasting) await this.startBroadcast(sessionId, storeId);
      this.isPromoVideoPlaying$.next(false);
    }
  }

  private async createVideoElement(videoUrl: string): Promise<HTMLVideoElement> {
    const videoElement = document.createElement('video');
    videoElement.src = videoUrl;
    videoElement.crossOrigin = 'anonymous';
    videoElement.autoplay = false;
    videoElement.muted = false;
    const timeoutDuration = 5000;

    return new Promise<HTMLVideoElement>((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Video load timeout: onloadeddata or onerror was not triggered.'));
      }, timeoutDuration);

      videoElement.onloadeddata = () => {
        clearTimeout(timeout);
        resolve(videoElement);
      };

      videoElement.onerror = (error) => {
        clearTimeout(timeout);
        reject(new Error(`Video load error: ${error}`));
      };
    });
  }
  private initializeCanvas(): {canvas: HTMLCanvasElement; ctx: CanvasRenderingContext2D} {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Failed to get canvas rendering context');

    const cameraFeedElement = document.querySelector('.camera-feed');
    if (!cameraFeedElement) throw new Error('Camera-feed element not found');

    canvas.width = cameraFeedElement.clientWidth;
    canvas.height = cameraFeedElement.clientHeight;

    return {canvas, ctx};
  }

  private attachCanvasToLocalView(canvas: HTMLCanvasElement): void {
    const localCanvasContainer = document.getElementById('agora-stream');
    if (localCanvasContainer) {
      localCanvasContainer.innerHTML = '';
      localCanvasContainer.appendChild(canvas);
    }
  }

  private startDrawingFrames(
    videoElement: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ): void {
    let isPlaying = true;

    const drawFrame = () => {
      if (isPlaying && !videoElement.paused && !videoElement.ended) {
        const {drawWidth, drawHeight, offsetX, offsetY} = this.calculateVideoFit(
          canvas,
          videoElement
        );

        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          videoElement,
          0,
          0,
          videoElement.videoWidth,
          videoElement.videoHeight,
          offsetX,
          offsetY,
          drawWidth,
          drawHeight
        );

        requestAnimationFrame(drawFrame);
      }
    };

    videoElement.onplay = () => {
      isPlaying = true;
      drawFrame();
    };
  }

  private calculateVideoFit(canvas: HTMLCanvasElement, videoElement: HTMLVideoElement) {
    const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
    const canvasAspectRatio = canvas.width / canvas.height;

    let drawWidth, drawHeight, offsetX, offsetY;

    if (videoAspectRatio > canvasAspectRatio) {
      drawWidth = canvas.width;
      drawHeight = canvas.width / videoAspectRatio;
      offsetX = 0;
      offsetY = (canvas.height - drawHeight) / 2;
    } else {
      drawWidth = canvas.height * videoAspectRatio;
      drawHeight = canvas.height;
      offsetX = (canvas.width - drawWidth) / 2;
      offsetY = 0;
    }

    return {drawWidth, drawHeight, offsetX, offsetY};
  }

  private createAgoraCanvasTrack(canvas: HTMLCanvasElement, agora: any): any {
    const canvasStream = canvas.captureStream(30);
    if (!canvasStream || !canvasStream.getVideoTracks().length)
      throw new Error('Failed to capture stream from canvas');
    return agora.createCustomVideoTrack({mediaStreamTrack: canvasStream.getVideoTracks()[0]});
  }

  private createAgoraAudioTrack(videoElement: HTMLVideoElement, agora: any): any {
    const audioStream = (
      videoElement as HTMLVideoElement & {captureStream?: any}
    ).captureStream?.();
    const audioTracks = audioStream?.getAudioTracks();

    if (!audioTracks || audioTracks.length === 0) {
      throw new Error('No audio track available in the video');
    }

    return agora.createCustomAudioTrack({mediaStreamTrack: audioTracks[0]});
  }

  private async handleVideoEnd(
    canvasTrack: any,
    audioTrack: any,
    wasBroadcasting: boolean,
    sessionId: string,
    storeId: string
  ): Promise<void> {
    if (this.client) {
      if (canvasTrack) {
        await this.client.unpublish([canvasTrack]);
        canvasTrack.close();
      }
      if (audioTrack) {
        await this.client.unpublish([audioTrack]);
        audioTrack.close();
      }
    }

    const localCanvasContainer = document.getElementById('agora-stream');
    if (!wasBroadcasting) {
      localCanvasContainer!.innerHTML = '';
    } else {
      await this.startBroadcast(sessionId, storeId);
    }

    this.isPromoVideoPlaying$.next(false);
  }

  public async startScreenShare(canvasStream: MediaStreamTrack) {
    try {
      await AgoraRTC().then(async (AgoraRTC) => {
        const canvasTrack = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: canvasStream,
          optimizationMode: 'motion',
        });
        const localVideoTrack = canvasTrack as ICameraVideoTrack;
        await this.client?.publish(localVideoTrack);
        this.play('agora-stream', localVideoTrack, {
          mirror: this.storesService.mirrorHostLocal,
        });
      });
    } catch (error) {
      this.logService.error('agora service ~ startScreenShare', error);
    }
  }

  private async stop() {
    let toUnpublish: boolean | undefined;
    this.client?.localTracks.forEach((track) => {
      toUnpublish = toUnpublish || track.getStats().sendBytes !== 0;
      track.close();
    });
    return toUnpublish && (await this.client?.unpublish());
  }

  public async stopScreenShare() {
    try {
      await this.stop();
    } catch (error) {
      this.logService.error('agora service ~ stopScreenShare', error);
    }
  }

  public async getHostBroadcastToken(sessionId: string, autoJoin: boolean) {
    if (this.broadcastToken?.sessionId === sessionId && this.client?.channelName === sessionId) {
      return this.broadcastToken;
    }
    await this.client
      ?.leave()
      .catch((e) =>
        this.logService.error('agora service ~ getHostBroadcastToken ~ this.client?.leave()', e)
      );

    const [BroadcastTokenData, videoDevices, audioDevices] = await Promise.all([
      lastValueFrom(
        this.sessionService.getBroadcastToken(sessionId, this.usersService.connectedUserSync.uid)
      ),
      this.getVideoOutputs().catch(() => []),
      this.getAudioOutputs().catch(() => []),
    ]);
    this.chosenCamera = this.chosenCamera ?? videoDevices[0];
    this.chosenMicrophone = this.chosenMicrophone ?? audioDevices[0];

    if (autoJoin) {
      try {
        await this.client?.join(
          environment.agora.appId,
          sessionId,
          BroadcastTokenData.broadcastToken,
          BroadcastTokenData.broadcastTokenUid
        );
        await this.client?.renewToken(BroadcastTokenData.broadcastToken);
      } catch (e) {
        this.logService.error(
          'agora service ~ getHostBroadcastToken ~ this.client?.join() || this.client?.renewToken',
          e
        );
      }
    }
    this.broadcastToken = {...BroadcastTokenData, sessionId};
    return this.broadcastToken;
  }

  public async startBroadcast(sessionId: string, storeId: string) {
    const {broadcastToken, broadcastTokenUid} = await this.getHostBroadcastToken(sessionId, true);
    await AgoraRTC().then(async (AgoraRTC) => {
      try {
        if (!this.chosenMicrophone?.deviceId) {
          this.chosenMicrophone = (await this.getAudioOutputs())[0];
        }
        if (!this.chosenCamera?.deviceId) {
          this.chosenCamera = (await this.getVideoOutputs())[0];
        }
      } catch (_) {
        return;
      }
      this.closePreview();
      const [localAudioTrack, localVideoTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: 'speech_standard',
          microphoneId: this.chosenMicrophone.deviceId,
        }),
        AgoraRTC.createCameraVideoTrack({
          encoderConfig:
            transcodingPreset[
              this.storesService.getActiveStoreSync()?.fullData.broadcastResolution ?? '720p30fps'
            ], // Use transcodingPreset to map the resolution
          optimizationMode: 'motion',
          cameraId: this.chosenCamera.deviceId,
        }),
      ]);

      this.play('agora-stream', localVideoTrack, {
        mirror: this.storesService.mirrorHostLocal,
      });
      await this.client?.unpublish();
      await this.client?.publish([localAudioTrack, localVideoTrack]);
      this.state.isAgoraPlayingVideo$.next(true);
      if (!this.state.isAgoraRecording$.value) {
        this.startAgoraCloudRecording(
          sessionId,
          broadcastToken,
          broadcastTokenUid,
          storeId
        ).subscribe({
          next: (res) => {
            // recordingSid saved for stopping the record later
            this.recordingSid = res.recordingSid;
            this.state.isAgoraRecording$.next(true);
          },
          error: (e) =>
            this.logService.error('agora service ~ startBroadcast ~ startAgoraCloudRecording', e),
        });
      }
      this.state.iAmBroadcasting$.next(true);
    });

    return;
  }

  public async leaveChannel() {
    await this.client?.leave();
    this.analytics.logEvent('select_content', {
      path: this.router.url,
      user: this.client,
      content_type: 'leave_Channel',
    });
  }

  public async stopVideoBroadcastAndRecording(sessionId: string) {
    try {
      await Promise.all([
        this.stop().then(() => {
          this.state.isAgoraPlayingVideo$.next(false);
        }),
        lastValueFrom(this.stopAgoraCloudRecording(sessionId)).then(() => {
          this.state.isAgoraRecording$.next(false);
        }),
        this.analytics.logEvent('select_content', {
          path: this.router.url,
          content_type: 'stop_broadcast',
        }),
        this.getHostBroadcastToken(sessionId, false),
      ]);
    } catch (error) {
      this.logService.error('agora service ~ stopVideoBroadcast', error);
    }
  }

  public async stopVideoBroadcast() {
    try {
      await this.stop().then(() => {
        this.state.isAgoraPlayingVideo$.next(false);
      });
    } catch (error) {
      this.logService.error('agora service ~ stopVideoBroadcast', error);
    }
  }

  /**
   * Starts agora cloud recording. Must supply the broadcastTokenUid that was
   * used to generate the broadcast token. (it is saved as a constant when created)
   *
   * @param sessionId
   * @param token
   * @param broadcastTokenUid
   * @param storeId
   */
  public startAgoraCloudRecording(
    sessionId: string,
    token: string,
    broadcastTokenUid: number,
    storeId: string
  ) {
    return this.fns
      .httpsCallable('startAgoraCloudRecording')({
        sessionId,
        token,
        hostUid: broadcastTokenUid,
        storeId,
      })
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Stops agora cloud recording by calling Firebase function
   * Will work only if successful recording has started. otherwise - will return undefined
   *
   * @param sessionId
   */
  public stopAgoraCloudRecording(sessionId: string) {
    return this.fns
      .httpsCallable('stopAgoraCloudRecording')({
        sessionId,
        recordingSid: this.recordingSid,
      })
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  public async startDemoBackstage() {
    // todo remove this and use the startPreview method
    await AgoraRTC().then(async (AgoraRTC) => {
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '720p_2',
        optimizationMode: 'motion',
      });
      this.play('agora-stream', localVideoTrack, {
        mirror: this.storesService.mirrorHostLocal,
      });
    });
  }

  public async startPreview() {
    const agora = await AgoraRTC();
    if (this.state.isAgoraPlayingVideo$.value || this.appService.isMobile.value) {
      return;
    }

    if (!this.chosenCamera) {
      const cameras = await this.getVideoOutputs();
      if (cameras.length > 0) {
        this.chosenCamera = cameras[0];
        return;
      } else {
        return;
      }
    }

    this.client?.localTracks
      .filter((track) => track.trackMediaType === 'video')
      .forEach((track) => track.close());

    const localVideoTrack = await agora.createCameraVideoTrack({
      encoderConfig:
        transcodingPreset[
          this.storesService.getActiveStoreSync()?.fullData.broadcastResolution ?? '720p30fps'
        ],
      optimizationMode: 'motion',
      cameraId: this.chosenCamera.deviceId,
    });
    this.client?.localTracks
      .filter((track) => track.trackMediaType === 'video')
      .map(async (track) => {
        track?.isPlaying
          ? this.chosenCamera?.deviceId &&
            (await localVideoTrack.setDevice(this.chosenCamera?.deviceId))
          : this.play('agora-preview', localVideoTrack, {
              mirror: this.storesService.mirrorHostLocal,
            });
      });
    this.play('agora-preview', localVideoTrack, {
      mirror: this.storesService.mirrorHostLocal,
    });

    this.AutoStopPreview?.unsubscribe();
    this.AutoStopPreview = timer(10000).subscribe(() => this.closePreview());
  }

  private AutoStopPreview?: Subscription;

  public closePreview() {
    if (this.state.isAgoraPlayingVideo$.value) return;
    this.stop();
    this.AutoStopPreview = undefined;
  }

  public soundBackstage() {
    const previousVolume = -1;
    return timer(0, 100).pipe<number, number>(
      map(
        () =>
          this.client?.localTracks
            .find((t): t is IMicrophoneAudioTrack => t.trackMediaType === 'audio')
            ?.getVolumeLevel() ?? -1
      ),
      filter((currentVolume) => previousVolume !== currentVolume)
    );
  }

  public async getVideoOutputs(): Promise<MediaDeviceInfo[]> {
    await this.refreshPermissions();
    try {
      const videoDevices = await (await AgoraRTC()).getCameras();
      return videoDevices;
    } catch (error) {
      console.error('agora service ~ getVideoOutputs', error);
      return [];
    }
  }

  public async getAudioOutputs(): Promise<MediaDeviceInfo[]> {
    await this.refreshPermissions();
    try {
      const audioDevices = await (await AgoraRTC()).getMicrophones();
      return audioDevices;
    } catch (error) {
      console.error('agora service ~ getAudioOutputs', error);
      return [];
    }
  }

  public async switchVideoOutput(
    device: MediaDeviceInfo | undefined,
    isBroadcasting = true,
    isChangeCameraButton = false
  ) {
    if (isBroadcasting) {
      this.refreshPermissions(isBroadcasting);
    }
    const previousDevice = this.chosenCamera;
    try {
      if (device && !isChangeCameraButton) {
        try {
          await this.appService.showConfirmationModal(
            this.languageService.translateSync('CONFIRM_MEDIA_DEVICE_SWITCHING.TITLE'),
            this.languageService.translateSync(
              `CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_BEFORE_DEVICE_NAME`
            ) +
              device.label +
              '" ' +
              this.languageService.translateSync(
                'CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_AFTER_DEVICE_NAME'
              ),
            this.languageService.translateSync(`CONFIRM_MEDIA_DEVICE_SWITCHING.CONFIRM_TXT`)
          );
        } catch (error) {
          this.chosenCamera = previousDevice;
          return false;
        }
      }
      this.chosenCamera = device;
      if (!device) return true;

      // Find the current track
      const currentTrack = this.client?.localTracks.find(
        (track) => track.trackMediaType === 'video'
      ) as ICameraVideoTrack;

      // Create new track
      const agora = await AgoraRTC();
      const newTrack = await agora.createCameraVideoTrack({
        cameraId: device.deviceId,
        encoderConfig:
          transcodingPreset[
            this.storesService.getActiveStoreSync()?.fullData.broadcastResolution ?? '720p30fps'
          ],
      });

      // If broadcasting, replace the track
      if (isBroadcasting && currentTrack) {
        await this.client?.unpublish(currentTrack);
        await this.client?.publish(newTrack);
        currentTrack.close();
      }

      // Update local display
      const agoraStreamElement = document.getElementById('agora-stream');
      if (agoraStreamElement) {
        agoraStreamElement.innerHTML = '';
        this.play('agora-stream', newTrack, {
          mirror: this.storesService.mirrorHostLocal,
        });
      }

      return true;
    } catch (error) {
      this.logService.error('agora service ~ switchVideoOutput', error);
      this.chosenCamera = previousDevice;
      return false;
    }
  }

  public async switchAudioOutput(device: MediaDeviceInfo | undefined) {
    const previousDevice = this.chosenMicrophone;
    try {
      this.chosenMicrophone = device;
      if (device && this.state.isAgoraPlayingVideo$.value) {
        await this.appService.showConfirmationModal(
          this.languageService.translateSync('CONFIRM_MEDIA_DEVICE_SWITCHING.TITLE'),
          this.languageService.translateSync(
            `CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_BEFORE_DEVICE_NAME`
          ) +
            device.label +
            '" ' +
            this.languageService.translateSync(
              'CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_AFTER_DEVICE_NAME'
            ),
          this.languageService.translateSync(`CONFIRM_MEDIA_DEVICE_SWITCHING.CONFIRM_TXT`)
        );
      }
      this.chosenMicrophone = device;

      this.client?.localTracks
        .filter((track) => track.trackMediaType === 'audio')
        .map(async (track) => {
          track?.isPlaying &&
            this.chosenCamera?.deviceId &&
            (await (track as ICameraVideoTrack).setDevice(this.chosenCamera?.deviceId));
        });
      return true;
    } catch (error) {
      this.chosenMicrophone = previousDevice;
      return false;
    }
  }

  private play(
    divId: string,
    videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined,
    options?: VideoPlayerConfig
  ) {
    let count = 0;
    const interval = setInterval(() => {
      if (!videoTrack) {
        clearInterval(interval);
        return;
      }
      if (count++ > 150) {
        // 15 seconds
        clearInterval(interval);
        return;
      }
      const elm = document.getElementById(divId);
      if (elm === null) return;
      elm.innerHTML = '';
      videoTrack.play(divId, options);
      clearInterval(interval);
    }, 100);
  }

  public async getCurrentVideoTrack(): Promise<MediaStreamTrack | undefined> {
    const localVideoTrack = this.client?.localTracks.find(
      (track) => track.trackMediaType === 'video'
    );
    return localVideoTrack?.getMediaStreamTrack();
  }
}
