import {inject, Injectable} from '@angular/core';
import {JSONObject, LocalStorageService} from './local-storage.service';
import {LogService} from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralUserIdService {
  private localStoreService = inject(LocalStorageService);
  private logger = inject(LogService);

  /**
   * @param sessionId
   */
  checkReferralUser(sessionId: string): boolean {
    let value = false;
    try {
      const val = this.localStoreService.getItem<JSONObject>(`sessionReferral-${sessionId}`);
      if (val) {
        value = true;
      }
    } catch (error) {
      this.logger.error('ReferralUserIdService checkReferralUser', error);
    }
    return value;
  }

  /**
   * @param sessionId
   * @param refererUserId
   */
  saveReferralUser(sessionId: string, refererUserId: string): void {
    const value: JSONObject = {
      'refererUserId': refererUserId,
    };
    this.localStoreService.setItem(`sessionReferral-${sessionId}`, value, {
      daysToSave: 14,
    });
  }

  /**
   * @param sessionId
   */
  getReferralUser(sessionId: string): string | null {
    try {
      const val = this.localStoreService.getItem<JSONObject>(`sessionReferral-${sessionId}`);
      if (val) {
        return val['refererUserId'] as string;
      }
      return null;
    } catch (error) {
      this.logger.error('ReferralUserIdService getReferralUser', error);
    }
    return null;
  }
}
